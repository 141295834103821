<div class="section1">
  <div class="container">
    <div>
      <section>
        <br />
        <h2>
          Enter in a valid NPI of the prescriber who is prescribing and
          enrolling the patient
        </h2>
        <br />
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
          <mat-form-field appearance="outline">
            <mat-label>NPI Number</mat-label>
            <input
              matInput
              required
              formControlName="npi"
              mask="0000000000"
              placeholder="NPI Number"
            />
            <mat-error *ngIf="npiControl?.hasError('required')">
              NPI number is required.
            </mat-error>
          </mat-form-field>
          <br />
          <div
            class="alert alert-danger"
            *ngIf="errorMessage && errorMessage.length > 0"
          >
            {{ errorMessage }}
          </div>
          <div class="row">
            <div class="col-md-2"><button mat-stroked-button class="default-btn" type="submit" [disabled]="isLoading">
              {{ isLoading ? "Searching..." : "Submit" }}
            </button></div>
            <div class="col-sm-1"><mat-spinner [diameter]="30" *ngIf="isLoading"></mat-spinner>
            </div>
          </div>
        </form>
      </section>
    </div>
  </div>
</div>
