import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { SignatureTypes } from '../../models/enums';

@Component({
  selector: 'app-signature-information',
  templateUrl: './signature-information.component.html',
  styleUrls: ['./signature-information.component.scss'],
})
export class SignatureInformationComponent implements OnInit {
  public formArray: FormArray;

  constructor(
    private formBuilder: FormBuilder,
    public formGroupDirective: FormGroupDirective
  ) {
    this.formArray = this.formBuilder.array([
      this.formBuilder.group({
        isAvailableToSign: this.formBuilder.control(null, Validators.required),
        signatureType: this.formBuilder.control(
          SignatureTypes.Prescriber,
          Validators.required
        ),
      }),
    ]);
  }

  public ngOnInit(): void {
    this.formGroupDirective.form.addControl('signatures', this.formArray);
    this.formArray.setParent(this.formGroupDirective.form);
  }
}
