export enum GenderTypes {
  Undefined = 0,
  Male = 1,
  Female = 2,
}

export enum DocumentTypes {
  Undefined = 0,
  InsuranceCard = 1,
  GovernmentId = 2,
  Other = 3,
}

export enum SignatureTypes {
  Undefined = 0,
  Prescriber = 1,
  Patient = 2,
}
