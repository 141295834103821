import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigModel } from '../models/configModel';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  constructor(private httpClient: HttpClient) {}

  public configuration: ConfigModel | undefined;
  //load pathways configurations
   public loadConfiguration() {
    let url = `${this.loadUrl()}/configuration`;
    this.httpClient.get<ConfigModel>(url)
      .toPromise()
      .then(result => {
        this.configuration = result;
      }, error => console.error(error));
  }

  //load API url based on env
  public loadUrl(): string {
    if (location.origin.toString() === 'https://accessosmolex-dv.ubcmain.com') {
      return 'https://accessosmolex-api-dv.ubcmain.com';
    }
    else if (location.origin.toString() === 'https://accessosmolex-tv.ubcmain.com') {
      return 'https://accessosmolex-api-tv.ubcmain.com';
    }
    else if (location.origin.toString() === 'https://accessosmolex.ubc-training.com') {
      return 'https://adamasportalapi.ubc-training.com';
    }
    else if (location.origin.toString() === 'https://accessosmolex.com') {
      return 'https://adamasportalapi.com';
    }
    else if (location.origin.toString() === 'https://localhost:4200')
      return 'https://localhost:44325';
    return '';
     
  }
}
