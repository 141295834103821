import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'patient-form',
  templateUrl: './patient-form.component.html',
  styleUrls: ['./patient-form.component.scss'],
})
export class PatientFormComponent implements OnInit {
  public form: FormGroup;
  public get firstNameControl(): AbstractControl | null {
    return this.form.get('firstName');
  }
  public get middleInitialControl(): AbstractControl | null {
    return this.form.get('middleInitial');
  }
  public get lastNameControl(): AbstractControl | null {
    return this.form.get('lastName');
  }
  public get dateOfBirthControl(): AbstractControl | null {
    return this.form.get('dateOfBirth');
  }
  public get genderControl(): AbstractControl | null {
    return this.form.get('gender');
  }
  public get isLongTermHealthCareFacilityControl(): AbstractControl | null {
    return this.form.get('isLongTermHealthCareFacility');
  }
  public get line1Control(): AbstractControl | null {
    return this.form.get('address.line1');
  }
  public get line2Control(): AbstractControl | null {
    return this.form.get('address.line2');
  }
  public get cityControl(): AbstractControl | null {
    return this.form.get('address.city');
  }
  public get stateControl(): AbstractControl | null {
    return this.form.get('address.state');
  }
  public get postalCodeControl(): AbstractControl | null {
    return this.form.get('address.postalCode');
  }
  public get primaryPhoneControl(): AbstractControl | null {
    return this.form.get('primaryPhone');
  }
  public get altPhoneControl(): AbstractControl | null {
    return this.form.get('altPhone');
  }

  constructor(
    private formBuilder: FormBuilder,
    private formGroupDirective: FormGroupDirective
  ) {
    this.form = this.formBuilder.group({
      firstName: this.formBuilder.control(null, Validators.required),
      middleInitial: this.formBuilder.control(null),
      lastName: this.formBuilder.control(null, Validators.required),
      dateOfBirth: this.formBuilder.control(null, Validators.required),
      gender: this.formBuilder.control(null, Validators.required),
      isLongTermHealthCareFacility: this.formBuilder.control(null),
      address: this.formBuilder.group({
        line1: this.formBuilder.control(null, Validators.required),
        line2: this.formBuilder.control(null),
        city: this.formBuilder.control(null, Validators.required),
        state: this.formBuilder.control(null, Validators.required),
        postalCode: this.formBuilder.control(null, Validators.required),
      }),
      primaryPhone: this.formBuilder.control(null, Validators.required),
      altPhone: this.formBuilder.control(null),
    });
  }

  public ngOnInit(): void {
    this.formGroupDirective.form.addControl('patient', this.form);
    this.form.setParent(this.formGroupDirective.form);
  }
}
