<div class="section1">
    <div class="container">
        <div class="row">
            <section class="col-lg-12 pt-5 pb-5">
                <h2>Please complete the 3 steps below to get your patient started on Osmolex:</h2>
                <br>
                <div class="row">
                    <ul>
                        <li>1</li>
                        <li>Complete the <span>Enrollment and Prescription form</span>.
                        </li>
                    </ul>
                </div>
                <div class="row">
                    <ul>
                        <li>2</li>
                        <li><span>Prescriber eSignature</span> - can be done immediately or emailed to prescriber for
                            signature.<br><i>(Prescriber email required)</i></li>
                    </ul>
                </div>
                <div class="row">
                    <ul>
                        <li>3</li>
                        <li><span>Patient authorization form</span> - have your patient sign the Patient Authorization
                            form via email.<br><i>(Patient email required)</i></li>
                    </ul>
                </div>
                <p><i>NOTE: Prescriber eSignature is required to initiate the fulfillment process.</i></p>
                <button mat-stroked-button [routerLink]="['/', 'npi-entry']" class="default-btn">Start the Enrollment and Prescription
                    Process<mat-icon>play_arrow</mat-icon></button>
            </section>
        </div>
    </div>
</div>