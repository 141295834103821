import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'prescription-information',
  templateUrl: './prescription-information.component.html',
  styleUrls: ['./prescription-information.component.scss'],
})
export class PrescriptionInformationComponent implements OnInit, OnDestroy {
  private subscriptions = new Array<Subscription | undefined>();
  public get titrationDoseControl(): AbstractControl | null {
    return this.form.get('titrationDose');
  }
  public get titrationDirectionsControl(): AbstractControl | null {
    return this.form.get('titrationDirections');
  }
  public get titrationQuantityControl(): AbstractControl | null {
    return this.form.get('titrationQuantity');
  }
  public get titrationRefillsControl(): AbstractControl | null {
    return this.form.get('titrationRefills');
  }
  public get maintenanceDoseControl(): AbstractControl | null {
    return this.form.get('maintenanceDose');
  }
  public get maintenanceDirectionsControl(): AbstractControl | null {
    return this.form.get('maintenanceDirections');
  }
  public get maintenanceQuantityControl(): AbstractControl | null {
    return this.form.get('maintenanceQuantity');
  }
  public get maintenanceRefillsControl(): AbstractControl | null {
    return this.form.get('maintenanceRefills');
  }
  public form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private formGroupDirective: FormGroupDirective
  ) {
    this.form = this.formBuilder.group({
      titrationDose: this.formBuilder.control(null),
      titrationDirections: this.formBuilder.control(null),
      titrationQuantity: this.formBuilder.control(null),
      titrationRefills: this.formBuilder.control("0"),
      maintenanceDose: this.formBuilder.control(null),
      maintenanceDirections: this.formBuilder.control(null),
      maintenanceQuantity: this.formBuilder.control(null),
      maintenanceRefills: this.formBuilder.control(null)
    });
  }

  public ngOnInit(): void {
    this.formGroupDirective.form.addControl('prescription', this.form);
    this.form.setParent(this.formGroupDirective.form);

    this.subscriptions.push(
      this.titrationDoseControl?.valueChanges.subscribe(
        (val)=>{
          if(val != null && !this.titrationQuantityControl?.touched){
            this.titrationQuantityControl?.setValue("30");
          }
        }
      )
    );

    this.subscriptions.push(
      this.maintenanceDoseControl?.valueChanges.subscribe(
        (val)=>{
          if(val != null && !this.maintenanceQuantityControl?.touched){
            this.maintenanceQuantityControl?.setValue("90");
          }
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      if(subscription != undefined){
        subscription.unsubscribe();
      }
    });
  }
}
