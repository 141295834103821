import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, BehaviorSubject } from "rxjs";
import { IOutcome } from 'src/app/models/outcome';
import { submitSignatureResponse } from "../models/submitSignatureResponse";
import { ConfigurationService } from '../services/configuration.service';
@Injectable({
  providedIn: "root"
})
export class SignatureService {

  constructor(private http: HttpClient, private configService: ConfigurationService) { }

  public submitSignatureForPage(enrollmentIdentity: number): Observable<IOutcome<submitSignatureResponse>> {
    let url = `${this.signatureUrl}`;
    if (enrollmentIdentity) {
      url += `/${enrollmentIdentity}`;
    }    return this.http.get<IOutcome<submitSignatureResponse>>(url);
  }

  public get signatureUrl(): string {
    return `${this.configService.loadUrl()}/signature`;
  }
}
