<form [formGroup]="form">
  <div class="row">
    <div class="col-md-6">
      <mat-label>Has the Patient Been Prescribed Amantadine IR?</mat-label>
      <mat-button-toggle-group
        formControlName="hasPatientPrescriberAmantadine"
        required
      >
        <mat-button-toggle [value]="true">Yes</mat-button-toggle>
        <mat-button-toggle [value]="false">No</mat-button-toggle>
      </mat-button-toggle-group>
      <mat-error
        *ngIf="
          hasPatientPrescriberAmantadineControl?.touched &&
          hasPatientPrescriberAmantadineControl?.hasError('required')
        "
      >
        Answer is required.
      </mat-error>
    </div>
    <div
      class="col-md-4"
      *ngIf="hasPatientPrescriberAmantadineControl?.value === true"
    >
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Date of Use</mat-label>
        <input
          matInput
          required
          formControlName="dateOfUse"
          placeholder="Date of Use"
          [matDatepicker]="picker"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error *ngIf="dateOfUseControl?.hasError('required')">
          Date of use is required.
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Patient's Diagnosis Code</mat-label>
        <input
          type="text"
          matInput
          formControlName="patientDiagnosisCode"
          placeholder="Patient's Diagnosis Code"
        />
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-md-4">
      <mat-label>Patient Received Samples</mat-label>
      <mat-button-toggle-group
        formControlName="patientReceivedSamples"
        required
      >
        <mat-button-toggle [value]="true">Yes</mat-button-toggle>
        <mat-button-toggle [value]="false">No</mat-button-toggle>
      </mat-button-toggle-group>
      <mat-error
        *ngIf="
          patientReceivedSamplesControl?.touched &&
          patientReceivedSamplesControl?.hasError('required')
        "
      >
        Answer is required.
      </mat-error>
    </div>
    <div class="col-md-4" *ngIf="patientReceivedSamplesControl?.value === true">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Sample Dose</mat-label>
        <input
          type="text"
          matInput
          formControlName="sampleDose"
          placeholder="Sample Dose"
        />
      </mat-form-field>
    </div>
    <div class="col-md-4" *ngIf="patientReceivedSamplesControl?.value === true">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Days Supply</mat-label>
        <input
          type="text"
          matInput
          formControlName="sampleDaysSupply"
          placeholder="Sample Dose"
        />
      </mat-form-field>
    </div>
  </div>
</form>
