import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeleteUploadFileDialogData } from '../../models/delete-upload-file-dialog';

@Component({
  selector: 'app-delete-upload-file-dialog',
  templateUrl: './delete-upload-file-dialog.component.html',
  styleUrls: ['./delete-upload-file-dialog.component.scss'],
})
export class DeleteUploadFileDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DeleteUploadFileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DeleteUploadFileDialogData
  ) {}

  ngOnInit(): void {}
}
