import { Pipe, PipeTransform } from '@angular/core';
import { DocumentTypes } from '../models/enums';

@Pipe({
  name: 'documentType',
})
export class DocumentTypePipe implements PipeTransform {
  transform(documentType: DocumentTypes): string {
    switch (documentType) {
      case DocumentTypes.GovernmentId:
        return 'Government Id';
      case DocumentTypes.InsuranceCard:
        return 'Insurance Card';
      case DocumentTypes.Other:
        return 'Other';
      default:
        return 'Unknown';
    }
  }
}
