import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'hcp-form',
  templateUrl: './hcp-form.component.html',
  styleUrls: ['./hcp-form.component.scss'],
})
export class HcpFormComponent implements OnInit {
  public get firstNameControl(): AbstractControl | null {
    return this.form.get('firstName');
  }
  public get lastNameControl(): AbstractControl | null {
    return this.form.get('lastName');
  }
  public get titlesControl(): AbstractControl | null {
    return this.form.get('titles');
  }
  public get stateLicenseNumberControl(): AbstractControl | null {
    return this.form.get('stateLicenseNumber');
  }
  public get npiControl(): AbstractControl | null {
    return this.form.get('npi');
  }
  public get officeNameControl(): AbstractControl | null {
    return this.form.get('officeName');
  }
  public get phoneControl(): AbstractControl | null {
    return this.form.get('phone');
  }
  public get faxControl(): AbstractControl | null {
    return this.form.get('fax');
  }
  public get emailControl(): AbstractControl | null {
    return this.form.get('email');
  }
  public get line1Control(): AbstractControl | null {
    return this.form.get('address.line1');
  }
  public get line2Control(): AbstractControl | null {
    return this.form.get('address.line2');
  }
  public get cityControl(): AbstractControl | null {
    return this.form.get('address.city');
  }
  public get stateControl(): AbstractControl | null {
    return this.form.get('address.state');
  }
  public get postalCodeControl(): AbstractControl | null {
    return this.form.get('address.postalCode');
  }
  public get officeContactFirstNameControl(): AbstractControl | null {
    return this.form.get('officeContactFirstName');
  }
  public get officeContactLastNameControl(): AbstractControl | null {
    return this.form.get('officeContactLastName');
  }
  public get officeContactPhoneControl(): AbstractControl | null {
    return this.form.get('officeContactPhone');
  }
  public get officeContactEmailControl(): AbstractControl | null {
    return this.form.get('officeContactEmail');
  }

  public form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private formGroupDirective: FormGroupDirective
  ) {
    this.form = this.formBuilder.group({
      firstName: this.formBuilder.control(null, Validators.required),
      lastName: this.formBuilder.control(null, Validators.required),
      titles: this.formBuilder.control([]),
      stateLicenseNumber: this.formBuilder.control(null),
      npi: this.formBuilder.control(null, Validators.required),
      email: this.formBuilder.control(null,Validators.required),
      officeName: this.formBuilder.control(null, Validators.required),
      phone: this.formBuilder.control(null, Validators.required),
      fax: this.formBuilder.control(null, Validators.required),
      address: this.formBuilder.group({
        line1: this.formBuilder.control(null, Validators.required),
        line2: this.formBuilder.control(null),
        city: this.formBuilder.control(null, Validators.required),
        state: this.formBuilder.control(null, Validators.required),
        postalCode: this.formBuilder.control(null, Validators.required),
      }),
      officeContactFirstName: this.formBuilder.control(
        null,
        Validators.required
      ),
      officeContactLastName: this.formBuilder.control(
        null,
        Validators.required
      ),
      officeContactPhone: this.formBuilder.control(null, Validators.required),
      officeContactEmail: this.formBuilder.control(null, [
        Validators.required,
        Validators.email
      ]),
    });
  }

  public ngOnInit(): void {
    this.formGroupDirective.form.addControl('healthcareProvider', this.form);
    this.form.setParent(this.formGroupDirective.form);
  }
}
