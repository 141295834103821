import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'adamas-portal-confirmation-page',
    templateUrl: './confirmation-page.component.html',
    styleUrls: ['./confirmation-page.component.scss']
})
export class ConfirmationPageComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
