<div class="row">
  <div class="col-md-6">
    <mat-form-field class="form-group w-100" appearance="outline">
      <mat-label>Type of Document</mat-label>
      <mat-select
        #documentTypeSelect
        matNativeControl
        [(ngModel)]="documentType"
      >
        <mat-option [value]="1">Insurance Card</mat-option>
        <mat-option [value]="2">Government Id</mat-option>
        <mat-option [value]="3">Other</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
<div class="row">
  <div class="col-md-6">
    <input
      #uploadFileControl
      class="w-100"
      type="file"
      (change)="fileChange($event)"
      accept=".pdf,.docx,.doc,.dot,.xlsx,.xls,.jpg,.png,.tiff,.txt"
    />
  </div>
  <div class="col-md-4">
    <button
      type="button"
      class="w-100"
      mat-raised-button
      color="primary"
      (click)="uploadFile()"
      [disabled]="!fileToUpload || isUploading || !documentType"
    >
      {{ isUploading ? "Uploading..." : "Upload" }}
    </button>
  </div>
</div>
<br /><br />
<div>
  Allowed upload file types:
  <br />
  <ul>
    <li>PDF (.pdf)</li>
    <li>Word (.docx , .doc, .dot)</li>
    <li>Excel (.xlsx , .xls)</li>
    <li>Image (.jpg, .png .tiff)</li>
    <li>Text (.txt)</li>
  </ul>
</div>
<div *ngIf="formArray.value.length > 0">
  <table class="table table-striped table-hover table-sm">
    <thead>
      <tr>
        <td><b>File Name</b></td>
        <td><b>Document Type</b></td>
        <td></td>
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="isUploading">
        <td colspan="4">
          <div class="row">
            <div class="offset-5">
              <div class="lds-facebook">
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </td>
      </tr>
      <tr *ngIf="!isUploading && (!formArray || formArray.value.length === 0)">
        <td colspan="4">No uploaded files...</td>
      </tr>
      <tr *ngFor="let f of formArray.value; let index">
        <td>{{ f.fileName }}</td>
        <td>{{ f.documentType | documentType }}</td>
        <td>
          <ng-container>
            <button
              mat-mini-fab
              color="warn"
              aria-label="Delete file"
              (click)="deleteFile(f, index)"
            >
              <mat-icon>delete</mat-icon>
            </button>
          </ng-container>
        </td>
      </tr>
    </tbody>
  </table>
</div>
