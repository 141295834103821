<form [formGroup]="form">
  <div class="row">
    <div class="col-md-4">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>First Name</mat-label>
        <input
          type="text"
          matInput
          required
          formControlName="firstName"
          placeholder="First Name"
        />
        <mat-error *ngIf="firstNameControl?.hasError('required')">
          First name is required.
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-md-4">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Last Name</mat-label>
        <input
          type="text"
          matInput
          required
          formControlName="lastName"
          placeholder="Last Name"
        />
        <mat-error *ngIf="lastNameControl?.hasError('required')">
          Last name is required.
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-md-4">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Title</mat-label>
        <mat-select formControlName="titles" multiple>
          <mat-option value="MD">MD</mat-option>
          <mat-option value="DO">DO</mat-option>
          <mat-option value="PA">PA</mat-option>
          <mat-option value="NP">NP</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-md-4">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>State License #</mat-label>
        <input
          type="text"
          matInput
          formControlName="stateLicenseNumber"
          placeholder="State License #"
        />
      </mat-form-field>
    </div>
    <div class="col-md-4">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Physician NPI #</mat-label>
        <input
          type="text"
          matInput
          required
          mask="0000000000"
          formControlName="npi"
          readonly
          placeholder="Physician NPI #"
        />
        <mat-error *ngIf="npiControl?.hasError('required')">
          Physician NPI # is required.
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-md-4">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Physician Email</mat-label>
        <input
          type="email"
          matInput
          required
          formControlName="email"
          placeholder="Physician Email"
        />
        <mat-error *ngIf="emailControl?.hasError('required')">
          Physician email is required.
        </mat-error>
        <mat-error *ngIf="emailControl?.hasError('email')">
          Invalid email address.
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-md-4">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Office Name</mat-label>
        <input
          type="text"
          matInput
          required
          formControlName="officeName"
          placeholder="Office Name"
        />
        <mat-error *ngIf="officeNameControl?.hasError('required')">
          Office name is required.
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-md-4">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Phone</mat-label>
        <input
          type="text"
          matInput
          mask="(000)-000-0000"
          required
          formControlName="phone"
          placeholder="Phone"
        />
        <mat-error *ngIf="phoneControl?.hasError('required')">
          Phone is required.
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-md-4">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Fax</mat-label>
        <input
          type="text"
          matInput
          mask="(000)-000-0000"
          required
          formControlName="fax"
          placeholder="Fax"
        />
        <mat-error *ngIf="faxControl?.hasError('required')">
          Fax is required.
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div formGroupName="address">
    <div class="row">
      <div class="col-md-4">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Street Address 1</mat-label>
          <input
            type="text"
            matInput
            required
            formControlName="line1"
            placeholder="Street Address 1"
          />
          <mat-error *ngIf="line1Control?.hasError('required')">
            Street address 1 is required.
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Street Address 2</mat-label>
          <input
            type="text"
            matInput
            formControlName="line2"
            placeholder="Street Address 2"
          />
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>City</mat-label>
          <input
            type="text"
            matInput
            required
            formControlName="city"
            placeholder="City"
          />
          <mat-error *ngIf="cityControl?.hasError('required')">
            City is required.
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>State</mat-label>
          <input
            type="text"
            matInput
            required
            mask="SS"
            formControlName="state"
            placeholder="State"
          />
          <mat-error *ngIf="stateControl?.hasError('required')">
            State is required.
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>ZIP</mat-label>
          <input
            type="text"
            matInput
            required
            mask="00000||00000-0000"
            formControlName="postalCode"
            placeholder="ZIP"
          />
          <mat-error *ngIf="postalCodeControl?.hasError('required')">
            Postal code is required.
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Office Contact First Name</mat-label>
        <input
          type="text"
          matInput
          required
          formControlName="officeContactFirstName"
          placeholder="Office Contact First Name"
        />
        <mat-error *ngIf="officeContactFirstNameControl?.hasError('required')">
          Office contact first name is required.
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-md-4">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Office Contact Last Name</mat-label>
        <input
          type="text"
          matInput
          required
          formControlName="officeContactLastName"
          placeholder="Office Contact Last Name"
        />
        <mat-error *ngIf="officeContactLastNameControl?.hasError('required')">
          Office contact last name is required.
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-md-4">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Office Contact Phone</mat-label>
        <input
          type="text"
          matInput
          required
          mask="(000)-000-0000"
          formControlName="officeContactPhone"
          placeholder="Office Contact Phone"
        />
        <mat-error *ngIf="officeContactPhoneControl?.hasError('required')">
          Office contact phone is required.
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-md-4">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Office Contact Email</mat-label>
        <input
          type="email"
          matInput
          required
          formControlName="officeContactEmail"
          placeholder="Office Contact Email"
        />
        <mat-error *ngIf="officeContactEmailControl?.hasError('required')">
          Office contact email is required.
        </mat-error>
        <mat-error *ngIf="officeContactEmailControl?.hasError('email')">
          Invalid email address.
        </mat-error>
      </mat-form-field>
    </div>
  </div>
</form>
