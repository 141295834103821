<footer>
    <div class="container">
        <div class="row">
            <div class="col-8">
                <p></p>
                <!-- needs to be replaced with actual value -->
            </div>
            <div class="col-4">
                <ul>
                    <li><a href="https://www.adamaspharma.com/privacy-policy" target="_blank">Privacy Policy</a></li>
                    <li><a href="https://www.adamaspharma.com/terms-of-use/" target="_blank">Terms of Use</a></li>
                </ul>
            </div>
        </div>
    </div>
</footer>