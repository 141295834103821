import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PatientEnrollmentRoutingModule } from './patient-enrollment-routing.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { NpiEntryPageComponent } from './pages/npi-entry-page/npi-entry-page.component';
import { EnrollmentFormPageComponent } from './pages/enrollment-form-page/enrollment-form-page.component';
import { ConfirmationPageComponent } from './pages/confirmation-page/confirmation-page.component';
import { HcpFormComponent } from './components/hcp-form/hcp-form.component';
import { PatientFormComponent } from './components/patient-form/patient-form.component';
import { MedicalInformationComponent } from './components/medical-information/medical-information.component';
import { PrescriptionInformationComponent } from './components/prescription-information/prescription-information.component';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatNativeDateModule } from '@angular/material/core';
import { NgxMaskModule } from 'ngx-mask';
import { SignatureInformationComponent } from './components/signature-information/signature-information.component';
import { UploadFileComponent } from './components/upload-file/upload-file.component';
import { DocumentTypePipe } from './pipes/document-type.pipe';
import { DeleteUploadFileDialogComponent } from './components/delete-upload-file-dialog/delete-upload-file-dialog.component';

@NgModule({
  declarations: [
    NpiEntryPageComponent,
    EnrollmentFormPageComponent,
    ConfirmationPageComponent,
    HcpFormComponent,
    PatientFormComponent,
    PrescriptionInformationComponent,
    MedicalInformationComponent,
    SignatureInformationComponent,
    UploadFileComponent,
    DocumentTypePipe,
    DeleteUploadFileDialogComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    PatientEnrollmentRoutingModule,
    MatInputModule,
    MatDialogModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatIconModule,
    MatRadioModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatButtonToggleModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatSelectModule,
    NgxMaskModule.forRoot(),
  ],
})
export class PatientEnrollmentModule {}
