import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IOutcome } from 'src/app/models/outcome';
import { DocumentTypes } from '../models/enums';
import { UploadFile } from '../models/upload-file';
import { ConfigurationService } from '../services/configuration.service';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  constructor(
    private configService: ConfigurationService,
    private httpClient: HttpClient
    
  ) {}

  public uploadFile(
    documentType: DocumentTypes,
    file: any,
    enrollmentId?: number
  ): Observable<IOutcome<UploadFile>> {
    const formData = new FormData();
    formData.append('file', file);

    let url = `${this.fileUploadUrl}/${documentType}`;
    if (enrollmentId) {
      url += `?enrollmentId=${enrollmentId}`;
    }

    return this.httpClient.post<IOutcome<UploadFile>>(url, formData);
  }

  public get fileUploadUrl(): string {
    return `${this.configService.loadUrl()}/file-upload`;
  }
}
