import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IOutcome } from 'src/app/models/outcome';
import { Enrollment } from '../models/enrollment';
import { ConfigurationService } from '../services/configuration.service';

@Injectable({
  providedIn: 'root',
})
export class EnrollmentService {
  constructor(
    private httpClient: HttpClient,
    private configService: ConfigurationService
  ) {}

  public createEnrollment(
    enrollment: Enrollment
  ): Observable<IOutcome<Enrollment>> {
    return this.httpClient.post<IOutcome<Enrollment>>(
      this.enrollmentUrl,
      enrollment
    );
  }

  private get enrollmentUrl(): string {
    return `${this.configService.loadUrl()}/enrollment`;
     
  }
}
