import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PhysicianApiResource } from '@huron/pathways-physician-legacy';
import { Observable } from 'rxjs';
import { Outcome } from '../models/Outcome';
import { ConfigurationService } from './configuration.service';
import { PathwaysTokenResponse } from '../models/PathwaysTokenResponse';

@Injectable({
  providedIn: 'root'
})
export class PhysicianService {
  constructor(private httpClient: HttpClient, private configService: ConfigurationService) {}

  public getPhysicians(npi: string, bearerToken: string): Observable<PhysicianApiResource[]>{
    let configuration = this.configService.configuration;
    let url = `${configuration?.basePathwaysUrl}physicians/4.0/?nPI=${npi}`; 
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${bearerToken}`)
    }
    return this.httpClient.get<PhysicianApiResource[]>(url, header);
  }

  public getPathwaysToken(): Observable<PathwaysTokenResponse>{
    let url = `${this.configService.loadUrl()}/pathwaystoken`;
    return this.httpClient.get<PathwaysTokenResponse>(url);
  }
}
