import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import {
  PhysicianConfigService,
  PhysicianQuery,
} from '@huron/pathways-physician-legacy';
import { Subscription } from 'rxjs';
import { PathwaysTokenResponse } from '../../models/PathwaysTokenResponse';
import { ConfigurationService } from '../../services/configuration.service';
import { EnrollmentDataService } from '../../services/enrollment-data.service';
import { PhysicianService } from '../../services/physician.service';

@Component({
  selector: 'adamas-portal-npi-entry-page',
  templateUrl: './npi-entry-page.component.html',
  styleUrls: ['./npi-entry-page.component.scss'],
})
export class NpiEntryPageComponent implements OnDestroy {
  public errorMessage?: string;
  public get npiControl(): AbstractControl | null {
    return this.form?.get('npi');
  }
  public isLoading = false;
  public form: FormGroup;
  private subscriptions = new Array<Subscription>();

  constructor(
    private formBuilder: FormBuilder,
    private physicianService: PhysicianService,
    private enrollmentDataService: EnrollmentDataService,
    private configService: ConfigurationService,
    private router: Router
  ) {
    this.form = this.formBuilder.group({
      npi: [null, [Validators.required]],
    });

    console.log(location.origin);
    this.configService.loadConfiguration();
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((x) => x.unsubscribe());
  }

  

  public onSubmit(): void {
    this.errorMessage = undefined;
    this.isLoading = true;
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      console.log('INVALID');
      return;
    }

    let pathwaysToken:string = "";

    this.physicianService.getPathwaysToken().subscribe(
      (response: PathwaysTokenResponse)=>{
        pathwaysToken = response.accessToken;
      },
      (error) => {
        this.errorMessage =
          'An error occured while connecting to Pathways';
      },
      ()=>{
        this.physicianService
        .getPhysicians(this.npiControl?.value, pathwaysToken)
        .subscribe(
          (physicians) => {
            if (physicians.length === 0) {
              this.errorMessage = `Provider with NPI: ${this.npiControl?.value} was not found.`;
              return;
            }
  
            const physician = physicians[0];
            this.enrollmentDataService.setPhysician(physician);
            this.isLoading = false;
            this.router.navigate(['/patient-enrollment', 'enrollment']);
          },
          (error) => {
            this.isLoading = false;
            this.errorMessage =
              'An error occured while retrieving NPI data. Please try a different NPI number.';
          }
        );
      }
    );

  }
}
