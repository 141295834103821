import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  FormGroupDirective,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DeleteUploadFileDialogData } from '../../models/delete-upload-file-dialog';
import { DocumentTypes } from '../../models/enums';
import { UploadFile } from '../../models/upload-file';
import { FileUploadService } from '../../services/file-upload.service';
import { DeleteUploadFileDialogComponent } from '../delete-upload-file-dialog/delete-upload-file-dialog.component';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss'],
})
export class UploadFileComponent implements OnInit {
  @ViewChild('uploadFileControl', { static: false })
  public uploadFileControl?: ElementRef;
  public formArray: FormArray;
  public documentType?: DocumentTypes;
  public errorMessage?: string;
  public allowedFileTypes: string[] = [
    '.pdf',
    '.docx',
    '.doc',
    '.dot',
    '.xlsx',
    '.xls',
    '.jpg',
    '.png',
    '.tiff',
    '.txt',
  ];
  public fileToUpload: any;
  public isUploading = false;

  constructor(
    private formBuilder: FormBuilder,
    private formGroupDirective: FormGroupDirective,
    private fileUploadService: FileUploadService,
    private matDialog: MatDialog
  ) {
    this.formArray = this.formBuilder.array([]);
  }

  public ngOnInit(): void {
    this.formGroupDirective.form.addControl('uploadFiles', this.formArray);
    this.formArray.setParent(this.formGroupDirective.form);
  }

  public fileChange(event: any) {
    this.errorMessage = undefined;

    const files = event.target.files;

    if (files && files.length > 0) {
      const file = files[0];
      const regex = /(?:\.([gG][^.]+))?$/;

      if (
        !this.allowedFileTypes.find((etx) =>
          (file.name.toLowerCase() as string).endsWith(etx)
        )
      ) {
        this.errorMessage = 'Unsupported file type.';
        return;
      }

      this.fileToUpload = file;
    }
  }

  public uploadFile(): void {
    this.errorMessage = undefined;
    if (!this.documentType || !this.fileToUpload) {
      this.errorMessage = 'Please select a file to upload';
      return;
    }

    this.isUploading = true;
    this.fileUploadService
      .uploadFile(this.documentType, this.fileToUpload)
      .subscribe(
        (outcome) => {
          this.isUploading = false;
          if (outcome.failure) {
            this.errorMessage = outcome.messages[0];
            return;
          }

          const form = this.formBuilder.group({
            documentType: outcome.value.documentType,
            fileName: outcome.value.fileName,
            contentType: outcome.value.contentType,
            id: outcome.value.id,
          });

          this.formArray.push(form);
          console.log(this.formArray);
          this.documentType = undefined;
          this.fileToUpload = undefined;
          if (this.uploadFileControl) {
            this.uploadFileControl.nativeElement.value = '';
          }
        },
        (error) => {
          this.isUploading = false;
          this.errorMessage =
            'An error occured while uploading the file. Please try again later.';
        }
      );
  }

  public deleteFile(file: UploadFile, index: number): void {
    const dialogRef = this.matDialog.open(DeleteUploadFileDialogComponent, {
      data: { file } as DeleteUploadFileDialogData,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!result) {
        return;
      }

      this.formArray.removeAt(index);
    });
  }
}
