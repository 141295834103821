import { Injectable } from '@angular/core';
import { PhysicianApiResource } from '@huron/pathways-core-legacy';
import { Enrollment } from '../models/enrollment';

@Injectable({
  providedIn: 'root',
})
export class EnrollmentDataService {
  private enrollment?: Enrollment;
  private physician?: PhysicianApiResource;

  constructor() {}

  public setEnrollment(enrollment: Enrollment): void {
    this.enrollment = enrollment;
  }

  public setPhysician(physician: PhysicianApiResource): void {
    this.physician = physician;
  }

  public getEnrollment(): Enrollment | undefined {
    return this.enrollment;
  }

  public getPhysician(): PhysicianApiResource | undefined {
    return this.physician;
  }
}
