<form [formGroup]="form">
  <div class="row">
    <div class="col-md-4">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>First Name</mat-label>
        <input
          type="text"
          matInput
          required
          formControlName="firstName"
          placeholder="First Name"
        />
        <mat-error *ngIf="firstNameControl?.hasError('required')">
          First name is required.
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-md-4">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>MI</mat-label>
        <input
          type="text"
          matInput
          mask="S"
          formControlName="middleInitial"
          placeholder="MI"
        />
      </mat-form-field>
    </div>

    <div class="col-md-4">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Last Name</mat-label>
        <input
          type="text"
          matInput
          required
          formControlName="lastName"
          placeholder="Last Name"
        />
        <mat-error *ngIf="lastNameControl?.hasError('required')">
          Last name is required.
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-md-4">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>DOB</mat-label>
        <input
          matInput
          formControlName="dateOfBirth"
          required
          [matDatepicker]="picker"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error *ngIf="dateOfBirthControl?.hasError('required')">
          DOB is required.
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-md-4">
      <mat-label>Gender</mat-label>
      <mat-button-toggle-group formControlName="gender" aria-label="gender">
        <mat-button-toggle [value]="1">Male</mat-button-toggle>
        <mat-button-toggle [value]="2">Female</mat-button-toggle>
      </mat-button-toggle-group>
      <mat-error
      *ngIf="
        genderControl?.touched &&
        genderControl?.hasError('required')
      "
    >
      Answer is required.
    </mat-error>
    </div>

    <div class="col-md-4">
      <mat-checkbox class="w-100" formControlName="isLongTermHealthCareFacility"
        >Long Term Care Facility</mat-checkbox
      >
    </div>
  </div>

  <div formGroupName="address">
    <div class="row">
      <div class="col-md-4">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Street Address 1</mat-label>
          <input
            type="text"
            matInput
            required
            formControlName="line1"
            placeholder="Street Address 1"
          />
          <mat-error *ngIf="line1Control?.hasError('required')">
            Street address 1 is required.
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Street Address 2</mat-label>
          <input
            type="text"
            matInput
            formControlName="line2"
            placeholder="Street Address 2"
          />
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>City</mat-label>
          <input
            type="text"
            matInput
            required
            formControlName="city"
            placeholder="City"
          />
          <mat-error *ngIf="cityControl?.hasError('required')">
            City is required.
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-4">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>State</mat-label>
          <input
            type="text"
            matInput
            required
            mask="SS"
            formControlName="state"
            placeholder="State"
          />
          <mat-error *ngIf="stateControl?.hasError('required')">
            State is required.
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-4">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>ZIP</mat-label>
          <input
            type="text"
            matInput
            required
            mask="00000||00000-0000"
            formControlName="postalCode"
            placeholder="ZIP"
          />
          <mat-error *ngIf="postalCodeControl?.hasError('required')">
            ZIP is required.
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-4">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Primary Phone</mat-label>
        <input
          type="text"
          matInput
          required
          mask="(000)-000-0000"
          formControlName="primaryPhone"
          placeholder="Primary Phone"
        />
        <mat-error *ngIf="primaryPhoneControl?.hasError('required')">
          Primary phone is required.
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-md-4">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Alternate Phone</mat-label>
        <input
          type="text"
          mask="(000)-000-0000"
          matInput
          formControlName="altPhone"
          placeholder="Alternate Phone"
        />
      </mat-form-field>
    </div>
  </div>
</form>
