import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'medical-information',
  templateUrl: './medical-information.component.html',
  styleUrls: ['./medical-information.component.scss'],
})
export class MedicalInformationComponent implements OnInit {
  public get hasPatientPrescriberAmantadineControl(): AbstractControl | null {
    return this.form.get('hasPatientPrescriberAmantadine');
  }

  public get dateOfUseControl(): AbstractControl | null {
    return this.form.get('dateOfUse');
  }

  public get patientDiagnosisCodeControl(): AbstractControl | null {
    return this.form.get('patientDiagnosisCode');
  }

  public get patientReceivedSamplesControl(): AbstractControl | null {
    return this.form.get('patientReceivedSamples');
  }

  public get sampleDoseControl(): AbstractControl | null {
    return this.form.get('sampleDose');
  }

  public get sampleDaysSupplyControl(): AbstractControl | null {
    return this.form.get('sampleDaysSupply');
  }

  public form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private formGroupDirective: FormGroupDirective
  ) {
    this.form = this.formBuilder.group({
      hasPatientPrescriberAmantadine: this.formBuilder.control(
        null,
        Validators.required
      ),
      dateOfUse: this.formBuilder.control(null),
      patientDiagnosisCode: this.formBuilder.control(null),
      patientReceivedSamples: this.formBuilder.control(
        null,
        Validators.required
      ),
      sampleDose: this.formBuilder.control(null),
      sampleDaysSupply: this.formBuilder.control(null),
    });
  }

  public ngOnInit(): void {
    this.formGroupDirective.form.addControl('medicalInformation', this.form);
    this.form.setParent(this.formGroupDirective.form);
  }
}
