<div class="container">
  <div class="osmolex-form">
    <div class="osmolex-form-bg">
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <mat-accordion multi>
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title> Patient Information </mat-panel-title>
            </mat-expansion-panel-header>
            <patient-form></patient-form>
          </mat-expansion-panel>
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>
                Healthcare Provider Information
              </mat-panel-title>
            </mat-expansion-panel-header>
            <hcp-form></hcp-form>
          </mat-expansion-panel>
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title> Medical Information </mat-panel-title>
            </mat-expansion-panel-header>
            <medical-information></medical-information>
          </mat-expansion-panel>
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>
                OSMOLEX ER Prescription Information
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div *ngIf="noDosageIsSelected"><p style="color:red;">At Least one of the Dosage Method is Required</p></div>
            <prescription-information></prescription-information>
          </mat-expansion-panel>
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>File Upload</mat-panel-title>
            </mat-expansion-panel-header>
            <app-upload-file></app-upload-file>
          </mat-expansion-panel>
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>Prescriber Signature</mat-panel-title>
            </mat-expansion-panel-header>
            <app-signature-information></app-signature-information>
          </mat-expansion-panel>
        </mat-accordion>
        <br />
        <div
          class="alert alert-danger"
          *ngIf="errorMessage && errorMessage.length > 0"
        >
          {{ errorMessage }}
        </div>

        <div class="row">
          <div class="col-md-2"><button mat-stroked-button class="default-btn" type="submit" [disabled]="isSaving">
            {{ isSaving ? "Submitting..." : "Submit" }}
          </button></div>
          <div class="col-sm-1"><mat-spinner [diameter]="30" *ngIf="isSaving"></mat-spinner>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
