<div *ngFor="let control of formArray.controls; let index">
  <div class="row">
    <div class="col-12">
      <div>
        <h6>
          Is the prescriber available to sign now? If yes, then click yes and
          provide signature now. If no, then an email will be sent to the
          prescriber's email address with a link in order to sign the form.
        </h6>
      </div>
      <mat-button-toggle-group
        [formControl]="$any(control).get('isAvailableToSign')"
        required
      >
        <mat-button-toggle [value]="true">Yes</mat-button-toggle>
        <mat-button-toggle [value]="false">No</mat-button-toggle>
      </mat-button-toggle-group>
      <mat-error
        *ngIf="
          $any(control).get('isAvailableToSign')?.touched &&
          $any(control).get('isAvailableToSign')?.hasError('required')
        "
      >
        Answer is required.
      </mat-error>
    </div>
  </div>
  <br />
  <mat-label
    >Note: The form must be signed by the prescriber before the enrollment and
    prescription form will be processed.</mat-label
  >
</div>
