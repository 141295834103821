import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Form, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { IOutcome } from 'src/app/models/outcome';
import { submitSignatureResponse } from '../../models/submitSignatureResponse';
import { EnrollmentService } from '../../services/enrollment.service';
import { SignatureService } from '../../services/signature.service';
import { EnrollmentDataService } from '../../services/enrollment-data.service';
import { HcpFormComponent } from '../../components/hcp-form/hcp-form.component';
import { ViewChild } from '@angular/core'
import { PrescriptionInformationComponent } from '../../components/prescription-information/prescription-information.component';
import { Enrollment } from '../../models/enrollment';

@Component({
  selector: 'adamas-portal-enrollment-form-page',
  templateUrl: './enrollment-form-page.component.html',
  styleUrls: ['./enrollment-form-page.component.scss'],
})
export class EnrollmentFormPageComponent implements OnInit, AfterViewInit {
  public errorMessage?: string;
  public form: FormGroup;
  public isSaving = false;
  public noDosageIsSelected: boolean = false;
  @ViewChild(HcpFormComponent)
  hcpform!: HcpFormComponent;
  @ViewChild(PrescriptionInformationComponent)
  prescriptionForm!: PrescriptionInformationComponent;
  constructor(
    private formBuilder: FormBuilder,
    private enrollmentService: EnrollmentService,
    private signatureService: SignatureService,
    private router: Router,
    private enrollmentdataservice: EnrollmentDataService
  ) {
    this.form = this.formBuilder.group({});
  }

  public title: string[] = [];

  ngOnInit() {
    this.title.push(this.enrollmentdataservice.getPhysician()?.suffix!);
  }

  ngAfterViewInit() {

    try {
      this.hcpform.lastNameControl?.setValue(this.enrollmentdataservice.getPhysician()?.lastName);
      this.hcpform.firstNameControl?.setValue(this.enrollmentdataservice.getPhysician()?.firstName);
      this.hcpform.titlesControl?.setValue(this.title);
      this.hcpform.stateLicenseNumberControl?.setValue(this.enrollmentdataservice.getPhysician()?.licenses[0].licenseId);
      this.hcpform.npiControl?.setValue(this.enrollmentdataservice.getPhysician()?.identifiers[0].issueId);

      const location = this.enrollmentdataservice.getPhysician()?.locations.find(location => location?.address?.addressType?.toString()?.toUpperCase() === "BUSINESS");

      this.hcpform.cityControl?.setValue(location?.address.city);
      this.hcpform.stateControl?.setValue(location?.address.state);
      this.hcpform.postalCodeControl?.setValue(location?.address.postalCode);
      this.hcpform.line1Control?.setValue(location?.address.address1);
      this.hcpform.line2Control?.setValue(location?.address.address2);

      const phone = this.enrollmentdataservice.getPhysician()?.locations.find(contacts => contacts.contactMethods != null)?.contactMethods.find(phone => phone.type.toString().toUpperCase() === "PHONE");

      const fax = this.enrollmentdataservice.getPhysician()?.locations.find(contacts => contacts.contactMethods != null)?.contactMethods.find(phone => phone.type.toString().toUpperCase() === "FAX");
      this.hcpform.phoneControl?.setValue(phone?.value);
      this.hcpform.faxControl?.setValue(fax?.value);
    } catch (error) {
      console.error('An error occured while getting physician details', error);
    }
  }

  private checkForDosageSelection(){

    if(this.prescriptionForm.titrationDoseControl?.value == null && this.prescriptionForm.maintenanceDoseControl?.value == null){
      this.noDosageIsSelected = true;
    }

    if(this.prescriptionForm.titrationDoseControl?.value != null || this.prescriptionForm.maintenanceDoseControl?.value != null){
      this.noDosageIsSelected = false;
    }

    if (this.prescriptionForm.titrationDoseControl?.value != null){
      this.prescriptionForm.titrationDoseControl?.setValidators([Validators.required]);
      this.prescriptionForm.titrationDoseControl?.updateValueAndValidity();
      this.prescriptionForm.titrationDirectionsControl?.setValidators([Validators.required]);
      this.prescriptionForm.titrationDirectionsControl?.updateValueAndValidity();
      this.prescriptionForm.titrationQuantityControl?.setValidators([Validators.required]);
      this.prescriptionForm.titrationQuantityControl?.updateValueAndValidity();
    }

    if (this.prescriptionForm.maintenanceDoseControl?.value != null){
      this.prescriptionForm.maintenanceDoseControl?.setValidators([Validators.required]);
      this.prescriptionForm.maintenanceDoseControl?.updateValueAndValidity();
      this.prescriptionForm.maintenanceDirectionsControl?.setValidators([Validators.required]);
      this.prescriptionForm.maintenanceDirectionsControl?.updateValueAndValidity();
      this.prescriptionForm.maintenanceQuantityControl?.setValidators([Validators.required]);
      this.prescriptionForm.maintenanceQuantityControl?.updateValueAndValidity();
      this.prescriptionForm.maintenanceRefillsControl?.setValidators([Validators.required]);
      this.prescriptionForm.maintenanceRefillsControl?.updateValueAndValidity();
    }
  }

  private sanitizeInputs(formValue:any): Enrollment{
    let sanitizedEnrollment: Enrollment = formValue;
    sanitizedEnrollment.prescription.titrationQuantity = sanitizedEnrollment.prescription.titrationQuantity === null? "" : sanitizedEnrollment.prescription.titrationQuantity+"";
    sanitizedEnrollment.prescription.titrationRefills = "";

    sanitizedEnrollment.prescription.maintenanceQuantity = sanitizedEnrollment.prescription.maintenanceQuantity === null? "" : sanitizedEnrollment.prescription.maintenanceQuantity+"";
    sanitizedEnrollment.prescription.maintenanceRefills = sanitizedEnrollment.prescription.maintenanceRefills === null? "" : sanitizedEnrollment.prescription.maintenanceRefills+"";

    return sanitizedEnrollment;
  }

  public onSubmit(): void {
    this.checkForDosageSelection();

    if(this.form.controls["medicalInformation"].get("hasPatientPrescriberAmantadine")?.value == false){
      this.form.controls["medicalInformation"].get("dateOfUse")?.setValue(null);
      this.form.controls["medicalInformation"].get("dateOfUse")?.clearValidators();
      this.form.controls["medicalInformation"].get("dateOfUse")?.updateValueAndValidity();
    }

    let enrollment: Enrollment = this.sanitizeInputs(this.form.value);
    this.form.markAllAsTouched();
    if (this.form.invalid || this.noDosageIsSelected == true) {
      return;
    }

    this.isSaving = true;

    this.enrollmentService.createEnrollment(enrollment).subscribe(
      (outcome) => {
        if (outcome.failure) {
          this.errorMessage = outcome.messages[0];
          return;
        }

        // Call signatures post api and get the signing links and redirect to assuresign or enrollment submitted page
        this.signatureService.submitSignatureForPage(outcome.value.id).subscribe((response: IOutcome<submitSignatureResponse>) => {
          this.isSaving = false;
          if (response.value.redirectUrl) {
            window.open(response.value.redirectUrl, "_self");
            return;
          }
          this.router.navigate(['/patient-enrollment', 'success']);
        }
        );
      },
      (error) => {
        this.isSaving = false;
        this.errorMessage = 'An unknown error occured. Please try again later.';
      }
    );
  }
}
