<h1 mat-dialog-title>Delete File Confirmation</h1>
<div mat-dialog-content>
  Are you sure you want to remove {{ data.file.fileName }}?
</div>
<div mat-dialog-actions>
  <button mat-button type="button" [mat-dialog-close]="false">No</button>
  <button mat-button type="button" [mat-dialog-close]="true" cdkFocusInitial>
    Yes
  </button>
</div>
