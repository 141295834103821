<div class="container-fluid header">
    <div class="row align-items-center">
        <div class="col-lg-6">
            <a class="navbar-brand" routerLink="/"><img src="../../../assets/images/osmolex-logo.png" /></a>
        </div>
        <div class="col-lg-6">
            <div>
                <h1 class="pt-3">Enrollment and Prescription</h1>
            </div>
        </div>
    </div>
</div>