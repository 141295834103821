<form [formGroup]="form">
  <mat-accordion multi>
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title> TITRATION DOSE* - 1st FILL </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="row">
        <div class="col-md-8">
          <mat-button-toggle-group formControlName="titrationDose">
            <mat-button-toggle value="129mg">129mg</mat-button-toggle>
            <mat-button-toggle value="193mg">193mg</mat-button-toggle>
            <mat-button-toggle value="258mg">258mg</mat-button-toggle>
          </mat-button-toggle-group>
          <mat-error
            *ngIf="
              titrationDoseControl?.touched &&
              titrationDoseControl?.hasError('required')
            "
          >
            Dose is required.
          </mat-error>
        </div>
      </div>
      <br />
      <h5>OSMOLEX ER (amantadine)</h5>
      <div class="row">
        <div class="col-md-12">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Directions</mat-label>
            <input
              type="text"
              matInput
              formControlName="titrationDirections"
              placeholder="Directions"
            />
            <mat-error *ngIf="titrationDirectionsControl?.hasError('required')">
              Directions are required.
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <h5>*Use if Intending to titrate</h5>
      <mat-label
        >^258 mg dose dispensed as 129 mg tablet + 129 mg tablet</mat-label
      >
      <div class="row">
        <div class="col-md-4">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Quantity</mat-label>
            <input
              type="number"
              matInput
              formControlName="titrationQuantity"
              placeholder="Quantity"
            />
            <mat-error *ngIf="titrationQuantityControl?.hasError('required')">
              Quantity is required.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Refills</mat-label>
            <input
              type="number"
              matInput
              readonly
              formControlName="titrationRefills"
            />
          </mat-form-field>
        </div>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title> MAINTENANCE DOSE - 2nd FILL </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="row">
        <div class="col-md-8">
          <mat-button-toggle-group formControlName="maintenanceDose" >
            <mat-button-toggle value="129mg">129mg</mat-button-toggle>
            <mat-button-toggle value="193mg">193mg</mat-button-toggle>
            <mat-button-toggle value="258mg">258mg</mat-button-toggle>
            <mat-button-toggle value="322mg">322mg†</mat-button-toggle>
          </mat-button-toggle-group>
          <mat-error
            *ngIf="
              maintenanceDoseControl?.touched &&
              maintenanceDoseControl?.hasError('required')
            "
          >
            Dose is required.
          </mat-error>
        </div>
      </div>
      <br />
      <h5>OSMOLEX ER (amantadine)</h5>
      <div class="row">
        <div class="col-md-12">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Directions</mat-label>
            <input
              type="text"
              matInput
              formControlName="maintenanceDirections"
              placeholder="Directions"
            />
            <mat-error
              *ngIf="maintenanceDirectionsControl?.hasError('required')"
            >
              Directions are required.
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div>
        <mat-label
          >^258 mg dose dispensed as 129 mg tablet + 129 mg tablet</mat-label
        >
      </div>
      <div>
        <mat-label
          >†322 mg dose dispensed as a 129 mg tablet + 193 mg tablet</mat-label
        >
      </div>
      <div class="row">
        <div class="col-md-4">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Quantity</mat-label>
            <input
              type="number"
              matInput
              formControlName="maintenanceQuantity"
              placeholder="Quantity"
            />
            <mat-error *ngIf="maintenanceQuantityControl?.hasError('required')">
              Quantity is required.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Refills</mat-label>
            <input
              type="number"
              matInput
              formControlName="maintenanceRefills"
              placeholder="Refills"
            />
            <mat-error *ngIf="maintenanceRefillsControl?.hasError('required')">
              Refills is required.
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</form>
