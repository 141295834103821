import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NpiEntryPageComponent } from './pages/npi-entry-page/npi-entry-page.component';
import { HomePageComponent } from '../../pages/home-page/home-page.component';
import { EnrollmentFormPageComponent } from './pages/enrollment-form-page/enrollment-form-page.component';
import { ConfirmationPageComponent } from './pages/confirmation-page/confirmation-page.component';
import { EnrollmentGuard } from './guards/enrollment.guard';

const ROUTES = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomePageComponent },
  { path: 'npi-entry', component: NpiEntryPageComponent },
  { path: 'enrollment', component: EnrollmentFormPageComponent,canActivate: [EnrollmentGuard]},
  { path: 'success', component: ConfirmationPageComponent },
];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(ROUTES)],
  exports: [RouterModule],
})
export class PatientEnrollmentRoutingModule {}
